// Dependencies
import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
import Accordian from "../components/Accordian";
import AnnotatedText from "../components/AnnotatedText";
import Layout from "../components/Layout";
import Header from "../components/Header";
import Markdown from "../components/Markdown";
import Footer from "../components/Footer";
import NewsletterForm from "../components/NewsletterForm";
import Vimeo from "../components/Vimeo";

const PageTemplate = ({ data }) => {

  const { title, sections } = data.contentfulPage

  return (
    <Layout title={title}>
      <Header />
      <div className="container">
        {sections && sections.map((section, index) => {
          const rowKey = `section-row-${index}`;

          return (
            section.copy && !section.faqs ? (
              <section className="grid page-section has-dark-links" key={rowKey}>
                <AnnotatedText className="col-4 p2 has-indents" richText={section.copyWithAnnotations} field={section.copy} />
                <div className="col-2">
                  <hr />
                  {section.vimeo && (
                    <div className="page-section-video">
                      <Vimeo url={section.vimeo.url} aspectRatio={section.vimeo.aspectRatio} />
                    </div>
                  )}
                </div>
              </section>
            ) : section.columns ? (
              <section className="grid page-section" key={rowKey}>
                {section.columns.map((column, index) => (
                  <div className="col-2" field={column.copy} key={`${rowKey}-column-${index}`}>
                    <Markdown field={column.copy} />
                    {column.includeNewsletterForm && (
                      <NewsletterForm />
                    )}
                  </div>

                ))}
              </section>
            ) : section.faqs ? (
              <section className="page-section" key={rowKey}>
                <Markdown className="p2 has-indents mbx4" field={section.copy}></Markdown>

                {section.faqs.map((entry, index) => (
                  <Accordian className="faq" label={() => (
                    <h1>{entry.question}</h1>
                  )} key={`faq-${index}`}>
                    <div className="grid innerx2" style={{ paddingBottom: '4rem' }}>
                      <Markdown field={entry.answer} className="has-dark-links p2 col-4" />
                      <div className="col-2"></div>
                    </div>
                  </Accordian>
                ))}
              </section>
            ) : null
          )
        })}
      </div>
      <Footer />
    </Layout>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default PageTemplate

export const pageQuery = graphql`
  query PageTemplateQuery($slug: String!){
    contentfulPage(slug: {eq: $slug}) {
      title
      slug
      sections {
        copy {
          childMarkdownRemark {
            html
          }
        }
        copyWithAnnotations {
          json
        }
        columns {
          copy {
            childMarkdownRemark {
              html
            }
          }
          includeNewsletterForm
          vimeoLink
        }
        vimeoLink
        vimeo {
          url
          aspectRatio
        }
        faqs {
          question
          answer {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
