// Dependencies
import React from "react"
// Hooks
import useVimeo from "../hooks/useVimeo"

const Vimeo = ({ url, aspectRatio }) => {

  const [ref, ready] = useVimeo({ url: url })

  return (
    <div className="prel inlb w100" >
      <div style={{
        paddingTop: `calc(${aspectRatio} * 100%)`
      }} />
      <div className="pabs fs fs-iframe" style={{
        opacity: ready ? 1 : 0, // 1 : 0
        transform: ready ? `scale(1)` : `scale(0.9)`,
        transition: '0.3s'
      }} ref={ref} />
      {!ready && (
        <div className="vimeo-loader pabs fs">
          <svg width="89" height="89" viewBox="0 0 89 89" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="89" height="89" fill="white"/>
            <g fillRule="evenodd" clipRule="evenodd" fill="black">
              <path d="M44 13.75C27.2934 13.75 13.75 27.2934 13.75 44C13.75 60.7066 27.2934 74.25 44 74.25C60.7066 74.25 74.25 60.7066 74.25 44C74.25 43.5858 74.5858 43.25 75 43.25C75.4142 43.25 75.75 43.5858 75.75 44C75.75 61.535 61.535 75.75 44 75.75C26.465 75.75 12.25 61.535 12.25 44C12.25 26.465 26.465 12.25 44 12.25C48.1603 12.25 52.1354 13.0505 55.7784 14.5067C56.163 14.6604 56.3502 15.0968 56.1964 15.4815C56.0427 15.8661 55.6063 16.0533 55.2216 15.8995C51.7528 14.513 47.9664 13.75 44 13.75Z" />
              <path d="M75.4983 43.4395C75.8079 43.7146 75.8358 44.1887 75.5606 44.4983L67.5606 53.4983C67.2854 53.8079 66.8113 53.8358 66.5017 53.5606C66.1921 53.2854 66.1643 52.8113 66.4395 52.5017L74.4395 43.5017C74.7146 43.1921 75.1887 43.1643 75.4983 43.4395Z" />
              <path d="M74.6646 43.3292C74.2941 43.5145 74.144 43.965 74.3292 44.3355L79.3292 54.3355C79.5144 54.7059 79.9649 54.8561 80.3354 54.6709C80.7059 54.4856 80.8561 54.0351 80.6708 53.6646L75.6708 43.6646C75.4856 43.2941 75.0351 43.144 74.6646 43.3292Z" />
            </g>
          </svg>
        </div>
      )}
    </div>
  )
}

Vimeo.defaultProps = {
  url: 'https://vimeo.com/321249877'
};

export default Vimeo
