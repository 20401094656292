// Dependencies
import React, { useState } from "react";
// import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
import { Chevron } from "../components/Icons";

const Accordian = ({ className, label, isOpen = false, children }) => {
  const [open, setOpen] = useState(isOpen);

  return (
    <div className={`accordian-section`}>
      <div className="accordian-section-tab" onClick={() => setOpen(o => !o)}>
        {label()}
        <div className="inlb mr" style={{
          transform: `rotate(${open ? '180deg' : '0deg'})`
        }}><Chevron /></div>
      </div>
      <div>
        {open && (
          children
        )}
      </div>
    </div>
  )
}

export default Accordian
